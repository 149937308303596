<template>
    <v-card>
        <v-card-text>
        <v-text-field :value="value.name" label="Name" @input="(value) => updateAttr('name', value)" dense/>
        <v-text-field :value="value.line1" label="Line 1" @input="(value) => updateAttr('line1', value)" dense/>
        <v-text-field :value="value.line2" label="Line 2" @input="(value) => updateAttr('line2', value)" dense/> <!-- TODO: "+" button for additional lines 3, 4, and 5 (max) -->
        <v-text-field :value="value.city" label="City" @input="(value) => updateAttr('city', value)" dense/> <!-- TODO: regular input but with autocomplete on known city/town names in the selected state -->
        <v-text-field :value="value.state" label="State" @input="(value) => updateAttr('state', value)" dense/> <!-- TODO: select list with "combo box" so user can start yping in a state name to find it in list... also load available state names after country is selected !! -->
        <v-text-field :value="value.code" label="Postal code" @input="(value) => updateAttr('code', value)" dense/>
        <v-text-field :value="value.country" label="Country" @input="(value) => updateAttr('country', value)" dense/> <!-- TODO: select list with "combo box" so user can start typing in a country name to find it in list -->
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({
                name: null,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
                line5: null,
                city: null,
                state: null,
                code: null,
                country: null,
            }),
        },
    },
    computed: {
    },
    methods: {
        updateAttr(key, value) {
            this.$emit('input', { ...this.value, [key]: value });
        },
    },
};
</script>
