<template>
    <v-row no-gutters>
        <v-col v-for="(item, idx) in steplist" :key="idx" :style="textAlignStyle(idx)" :cols="cols">
            <!-- <font-awesome-icon :icon="['fas', 'circle']" fixed-width/> -->
            <span style="font-size: 0.8em;" :class="{ 'current-step': item.value === step }">
                <span class="circled">{{ idx + 1 }}</span>
                &nbsp;
                <span :class="{ 'underlined': item.value === step }">{{ item.text }}</span>
                &nbsp;
                <!-- &rsaquo; -->
            </span>
        </v-col>
    </v-row>
</template>

<style>
.current-step {
    font-weight: bold;
}
/* circled numbers for the progress indicator */
.underlined {
    border-bottom: 1px solid black;
}
.circled {
    border-radius: 15%;
    /* width: 24px; */
    /* height: 24px; */
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    text-align: center;
    font-family: sans-serif;
}
</style>

<script>
export default {
    props: ['step', 'shipping', 'billing'],
    computed: {
        steplist() {
            const list = [];
            if (this.shipping) {
                list.push({ text: 'Shipping', value: 'shipping' });
            }
            if (this.billing) {
                list.push({ text: 'Billing', value: 'billing' });
            }
            list.push({ text: 'Finalize', value: 'finalize' });
            list.push({ text: 'Payment', value: 'payment' });
            list.push({ text: 'Receipt', value: 'receipt' });
            return list;
        },
        stepcount() {
            return this.steplist.length;
        },
        cols() {
            return Math.max(Math.ceil(12 / this.stepcount), 2); // at least 2 columns... after that we need to wrap or scroll
        },
    },
    methods: {
        textAlignStyle(idx) {
            let css;
            if (idx === 0) {
                css = 'text-align: start;';
            } else if (idx === this.stepcount - 1) {
                css = 'text-align: end;';
            } else {
                css = 'text-align: center;';
            }
            return css;
        },
    },
};
</script>
